import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ahahah from "../static/ah-ah-ah.webp"
import ahahahaudio from "../static/ah-ah-ah.mp3"

const NotFoundPage = () => (
    <>
    <Layout>
      <SEO title="404: Not found" />
      <audio controls autoPlay loop src={ahahahaudio} type="audio/mp3" />
      <div style={{display: `flex`, justifyContent: `center`, alignItems: `center`, height: `800px`}}>
        <img src={ahahah} alt="ah-ah-ah" />
      </div>
    </Layout>
    </>
)

export default NotFoundPage
